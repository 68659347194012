window.addEventListener('load', () => {
    document.querySelector('body').style.opacity = 1
})

window.addEventListener('DOMContentLoaded', () => {
    menuInit()
    new WOW().init()
    sliderInit()
    sideImagesControlInit()
    formInit()

    document.querySelector('.year').textContent = `${new Date().getFullYear()} © Actimind Inc.`
})

// Управление боковым меню
function menuInit() {
    const headerOpenElement = document.querySelector('.header__humburger')
    const headerCloseElement = document.querySelector('.header__close img')
    const headerMenuElement = document.querySelector('.header__menu')
    const bodyElement = document.querySelector('body')

    headerOpenElement.addEventListener('click', () => {
        headerMenuElement.classList.add('header__menu--active')
        setTimeout(() => {
            bodyElement.style.overflow = 'hidden'
        }, 400)
    })

    headerCloseElement.addEventListener('click', () => {
        headerMenuElement.classList.remove('header__menu--active')
        bodyElement.style.overflow = 'auto'
    })

    // Плавный скролл
    const anchors = [...document.querySelectorAll('a[href*="#"]')]

    anchors.forEach((anchor) => {
        anchor.addEventListener('click', (event) => {
            event.preventDefault()

            // prettier-ignore
            document
                .querySelector(`[name='${anchor.getAttribute('href').slice(1)}']`)
                .scrollIntoView({ behavior: 'smooth' })

            headerMenuElement.classList.remove('header__menu--active')
            bodyElement.style.overflow = 'auto'
        })
    })
}

// Управление слайдерами со скриншотами
function sliderInit() {
    const swiper = new Swiper('.swiper', {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 30,
        centeredSlides: true,
        freeMode: true,
        freeModeMomentum: false,
        speed: 43000,
        autoplay: {
            delay: 16,
            disableOnInteraction: false,
        },
    })

    const swiper2 = new Swiper('.swiper2', {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 30,
        centeredSlides: true,
        freeMode: true,
        freeModeMomentum: false,
        speed: 50000,
        autoplay: {
            reverseDirection: true,
            delay: 16,
            disableOnInteraction: false,
        },
    })
}

// Управление появлением и исчезновением боковых картинок в нижней части лендинга
function sideImagesControlInit() {
    const leftImageElement = document.querySelector('.how__left-image')
    const rightImageElement = document.querySelector('.how__right-image')
    const whoElement = document.querySelector('.who')

    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    leftImageElement.classList.add('how__left-image--hidden')
                    rightImageElement.classList.add('how__right-image--hidden')
                } else {
                    leftImageElement.classList.remove('how__left-image--hidden')
                    rightImageElement.classList.remove('how__right-image--hidden')
                }
            })
        },
        { threshold: 0 }
    )

    observer.observe(whoElement)
}

function formInit() {
    const regExpEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const requiredElement = document.querySelector('.required-fieldset__items')
    const emailElement = document.querySelector('input[name=email]')
    const firstnameElement = document.querySelector('input[name=firstname]')
    const lastnameElement = document.querySelector('input[name=lastname]')
    const formButtonElement = document.querySelector('.form__button')
    let isValid = false

    requiredElement.addEventListener('keyup', function () {
        isValid = regExpEmail.test(emailElement.value) && firstnameElement.value && lastnameElement.value
        if (isValid) {
            formButtonElement.classList.remove('form__button--disable')
        } else {
            formButtonElement.classList.add('form__button--disable')
        }
    })

    let dataIsFetched = false

    // prettier-ignore
    document
        .querySelector('.bottom-form')
        .addEventListener('submit', function(event) {
            event.preventDefault()

            if (!dataIsFetched) {
                dataIsFetched = true

                if (!regExpEmail.test(emailElement.value)) {
                    return
                }

                const data = Array.from(this.elements)
                    .map(element => {
                        let value = null
                        const { name, type } = element
                        if ((type === 'checkbox' && element.checked) || type !== 'checkbox') {
                            value = element.value
                        }
                        return { question: name, answer: value }
                    })
                    .filter(item => item.question && item.answer) // Отбрасываем пустые значения

                try {
                    fetch('/webForms/submit', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                        formId: 'actimind-blog-form1',
                        fields: data
                        })
                    })
                    .then((response) => {
                        formButtonElement.textContent = 'Sended!'
                    })
                } catch (error) {
                    console.log(error);
                }
            }
        })
}
